<template>
  <div class="is-width-5/6 m-h-a">
    <div class="">
      <Heading
        size="4"
        weight="bold"
        color="white"
        :serif="false"
      >
        {{ $t('pages.login.title') }}
      </Heading>
      <login-form home-route-name="home" />
    </div>
    <div class="m-t-xxxl has-text-centered">
      <a
        :href="hfUrl"
        target="_blank"
        class="has-text-white has-text-decoration-underline"
      >
        {{ $t('pages.login.dont_have_acc_yet') }}
      </a>
    </div>
  </div>
</template>

<script>
import LoginForm from '@hypefactors/shared/js/components/auth/LoginForm'
import Heading from '@hypefactors/shared/js/components/core/Heading'

export default {
  name: 'Login',
  components: {
    Heading,
    LoginForm
  },
  data () {
    return {
      hfUrl: 'https://hypefactors.com/your-solution/'
    }
  }
}
</script>
